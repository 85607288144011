import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
    DeviceType,
    Carousel,
    Testimonial,
} from 'modules/references';

import Seo from '../../components/SEO';

import range from 'lodash/range';

import { ReferenceCategory } from '../../constants';

import socialPreview from '../../../static/social-previews/travel-free.png';

const CASE_ID = 'travelFree';
const MODULE_NAME = 'travel-free';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    black: '#000',
    yellow: '#FFFAC9',
    green: '#E3FFDD',
    white: '#FFF',
    pink: '#FFE3E7',
    blue: '#E4FAFF',
    gray: '#F6F4F4',
    lightGray: '#F9F9F9',
    bgPurpleGradient: 'radial-gradient(#EEECF5, #DBD8E6)',
};

const team = [
    { department: 'references.case.team.management', names: ['Tomáš Marek'] },
    {
        department: 'references.case.team.design',
        names: ['Michal Mikolaj', 'Anna Mescheryakova'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Alex Grigorov', 'Daniel Ridzoň'],
    },
    {
        department: 'references.case.team.android',
        names: ['Vojtěch Bešťák', 'Petr Šíma', 'Jan Steuer'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Simona Bubeníková', 'Mariya Vorona'],
    },
];

interface Props {}
const TravelFree = (props: Props) => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero_main.png`,
                    imgStyle: { objectFit: 'contain', objectPosition: 'bottom center' },
                    style: {
                        width: '100%',
                    },
                    alt: `case.${CASE_ID}.hero.project`,
                },
            }}
            background={colors.bgPurpleGradient}
            backgroundImage={{
                src: `${IMAGE_PATH}/hero_bg.png`,
                imgStyle: { objectFit: 'cover', objectPosition: 'bottom' },
            }}
            headerTheme="dark"
            textColor={colors.black}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DIGITALIZATION,
                outputs: [
                    'references.case.output.ux',
                    'references.case.output.design',
                    'references.case.output.ios',
                    'references.case.output.android',
                ],
            }}
            background={colors.white}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            fullHeight={false}
            layout={GalleryLayout.LITTLE_OVER_ONE_HALF}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/gallery_01_main.png`,
                        imgStyle: {
                            objectPosition: 'center bottom',
                        },
                    },
                    background: colors.green,
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/gallery_01.png`,
                        imgStyle: {
                            objectFit: 'cover',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
            }}
            background={colors.white}
        />

        {/* @ts-expect-error */}
        <Gallery
            fullHeight={false}
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: colors.yellow,
                    image: {
                        src: `${IMAGE_PATH}/gallery_02.png`,
                        imgStyle: { objectFit: 'cover' },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={colors.white}
        />

        {/* @ts-expect-error */}
        <Carousel
            headerTheme="dark"
            deviceType={DeviceType.MOBILE}
            background={colors.pink}
            slides={range(1, 6).map(id => ({
                image: {
                    src: `references/case-studies/travel-free/carousel/${id}.png`,
                },
            }))}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
            background={colors.white}
        />

        {/* @ts-expect-error */}
        <Gallery
            fullHeight={false}
            headerTheme="dark"
            layout={GalleryLayout.LITTLE_OVER_ONE_HALF}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/gallery_04.png`,
                        imgStyle: {
                            objectFit: 'cover',
                            objectPosition: 'center',
                        },
                    },
                    background: colors.blue,
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/gallery_04_main.png`,
                        imgStyle: {
                            objectFit: 'cover',
                        },
                    },
                    background: colors.gray,
                },
            ]}
        />

        {/* @ts-expect-error */}
        <Testimonial
            headerTheme="dark"
            background={colors.lightGray}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/img_person_travelfree.png`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
        />

        {/* @ts-expect-error */}
        <Team headerTheme="dark" content={team} background={colors.white} textColor={colors.black} />
    </>
);

export default TravelFree;
